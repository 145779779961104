import React, { useState } from "react"
import styled from "styled-components"
import { rootStyles } from "../style/shared"
import Flex from "./Flex"
import { BsEye, BsEyeSlash, BsSearch } from "react-icons/bs"

const InputPane = styled.input`
    padding: 0 ${rootStyles.padding.md}px 0 5px;
    margin: 0;
    width: 100%;
    min-width: 300px;
    height: 49px;
    color: ${rootStyles.colors.action};
    font-weight: 500;
    border: none !important;
    // border: 2px solid ${rootStyles.colors.inputBorderColor};
    border-radius: ${rootStyles.borderRadius.lg}px;
    transition: all 200ms ease-in-out;
    background-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
        background-color: white;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &:focus {
        outline: none;
        border: 2px solid ${rootStyles.colors.action};
        box-shadow: none;
    }

    &::placeholder{
        // color:${({ error }) => error ? "red" : rootStyles.colors.inputPlaceHolderColor};
        font-size: ${rootStyles.fontSize.sm}px;
        font-weight: 300 !important;
    }
`
const LabelPane = styled.label`
    font-size: ${rootStyles.fontSize.sm}px;
    font-weight: 600;

    &::after{
        content: ${({ optional }) => optional ? "dd" : " *"};
    };

    // color: ${({ error }) => error ? "red" : "inherit"};
    // color: red;
`
const InputContainer = styled.div`
    padding: 0 10px;
   display: flex;
   align-items: center;
   gap: 8px;
   background-color: #FFFFFF;
   border: 1px solid gray;
   border-color: ${({ error }) => error !== null ? "red" : rootStyles.colors.inputBorderColor};
   border-radius: ${rootStyles.borderRadius.lg}px;
//    padding-left: ${rootStyles.padding.md}px;
`
const LeftIconPane = styled.span`
   display: inline-block;
   padding: 0px 0px;
    margin-left: 2px;
`
// const SpanErrorPane = styled.span`
//     font-size: ${rootStyles.fontSize.sm}px;
//     color: red;
// `
const ErrorPane = styled.span`
    color: red;
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    font-weight: 300;
`
const RightIconPane = styled.span`
   position: absolute;
   right: 10px;
   top: 34px;
`

const Input = ({ type, placeholder, label, error = null, register, optional, isPassword, leftIcon }) => {
    const [passwordType, setPasswordType] = useState("password")

    return <div style={{ position: "relative", flex: 1, marginBottom: 13 }}>
        {
            label && <Flex justify={"space-between"} align={"center"}>
                {<LabelPane optional={optional} error={error}>{label}</LabelPane>}
                {/* {
                error && <SpanErrorPane>{error}</SpanErrorPane>
            } */}
            </Flex>
        }
        <InputContainer error={error}>
            {
                leftIcon && <LeftIconPane>
                    <BsSearch size={20} />
                </LeftIconPane>
            }
            {/* <LeftIconPane>
                <BsSearch size={20} />
            </LeftIconPane> */}
            <InputPane
                type={type === "password" ? passwordType : type}
                placeholder={placeholder}
                {...register}
            />

            {
                isPassword ?
                    type === "password" ? passwordType === "password" ?
                        <RightIconPane>
                            <BsEye
                                onClick={() => setPasswordType("text")}
                                size={20}
                            />
                        </RightIconPane>
                        : <RightIconPane>
                            <BsEyeSlash
                                onClick={() => setPasswordType("password")}
                                size={20}
                            />
                        </RightIconPane> :
                        null :
                    null
            }
        </InputContainer>
        <ErrorPane>{error}</ErrorPane>
    </div>
}

export default Input