import React, { Suspense } from 'react'
import Header from './header/Header'
import Navbar from './navbar/Navbar'
import { Navigate, Outlet } from 'react-router-dom'
import Footer from './footer/Footer'
import { MainContaintPane } from './AppShell'
import { useSelector } from 'react-redux'
import { HOME_PAGE, SALONS_PAGE } from '../routes/navigation_routes'
import PageLoading from '../features/reservation/components/PageLoading'

const AuthAppSell = () => {
    const { currentUser } = useSelector(state => state.user)
    const { currentCategory } = useSelector(state => state.categories)

    return currentUser?.access_token ? <Suspense fallback={<PageLoading />} >
        <Header />
        <Navbar />
        <MainContaintPane>
            <Outlet />
        </MainContaintPane>
        <Footer />
    </Suspense> : <Navigate to={HOME_PAGE + SALONS_PAGE + "?category=" + currentCategory.id} />
}

export default AuthAppSell