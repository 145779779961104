import React, { useEffect } from 'react'
import { AiOutlineStar } from 'react-icons/ai'
import MainContent from '../../../components/MainContent'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import { AdressePane, PrestationTitle, ReservationTitle, ReservationsPane, SelectedServicesPane, SpanTextPane } from './SalonReservationPage'
import Flex from '../../../components/Flex'
import Dot from '../../../components/Dot'
import Div from '../../../components/Div'
import ReservationFeature from '../../../features/reservation/ReservationFeature'
import Button from '../../../components/Button'
import { rootStyles } from '../../../style/shared'
import GoBack from '../../../components/GoBack'
import { useNavigate } from 'react-router-dom'
import { AUTH_PAGE, SALONS_PAGE } from '../../../routes/navigation_routes'
import { useMakeNewResersationMutation } from '../../../features/reservation/api/mutations'
import { successMsg } from '../../../helpers/successMsg';
import { errorMsg } from '../../../helpers/errorMsg'
import useTotalReservation from '../../../hooks/useTotalReservation'
import { resetCurrentSalonReservations } from '../../../redux/features/reservationSlice'
import { initialCategorieState, setCurrentCategory } from '../../../redux/features/categorySlice'

const SalonReservationUserConnected = () => {
    const { currentSalon } = useSelector(state => state.salons)
    const { currentSalonReservations } = useSelector(state => state.reservations)
    const { currentUser } = useSelector(state => state.user)
    const dispatch = useDispatch()

    const [makeNewReservation, { isLoading }] = useMakeNewResersationMutation()
    const { totalReservation } = useTotalReservation()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setCurrentCategory(initialCategorieState))
    }, [])

    const makeNewReservationFunc = () => {
        const appointmentServices = currentSalonReservations.map(reservation => {
            return {
                service_id: reservation.reservation.id,
                employee_id: reservation.employe?.value,
                start: reservation.date?.fullDate
            }
        })

        const appointment = {
            salon_id: currentSalon.id,
            client_id: currentUser.user.id,
            date: currentSalonReservations[0].date.fullDate,
            price: totalReservation,
            comment: "Here",
            services: appointmentServices,
        }

        makeNewReservation({ appointment }).unwrap()
            .then(() => {
                successMsg("Nouvelle reservation effectue avec succes")
                dispatch(resetCurrentSalonReservations({ idSalon: currentSalon.id }))
            })
            .catch((err) => {
                // console.log({ err });
                errorMsg(err.data.message)
            })
    }

    console.log({ currentSalon });


    return (
        <MainContent>
            <Div mb={40}>
                <GoBack label={"Retour"} navigateTo={() => navigate(-1)} />
            </Div>
            <ReservationTitle>{currentSalon.name}</ReservationTitle>
            <Flex align={"center"} gap={15} wrap={"wrap"}>
                <Flex minwidth={300}>
                    <AdressePane><HiOutlineLocationMarker />  {currentSalon.address}</AdressePane>
                </Flex>
                <Dot />
                <Flex align={"flex-end"}>
                    <Flex align={"center"} gap={2}>
                        <AiOutlineStar size={20} />
                        <SpanTextPane>{currentSalon.stars}</SpanTextPane>
                    </Flex>
                    <Div>
                        <SpanTextPane>({currentSalon.reviews} avis)</SpanTextPane>
                    </Div>
                </Flex>
            </Flex>
            <SelectedServicesPane>
                <PrestationTitle>1. Prestations sélectionnées</PrestationTitle>
                <ReservationsPane>
                    <ReservationFeature />
                </ReservationsPane>

                <Div mt={40}>
                    <Button
                        onClick={makeNewReservationFunc}
                        width={100}
                        letterSpacing={3}
                        fontSize={rootStyles.fontSize.sm}
                        fontWeight={600}
                        textTransform="uppercase"
                        paddingVertical={rootStyles.padding.md}
                        borderWidth={2}
                        borderStyle="solid"
                        borderColor={rootStyles.colors.action}
                        borderRadius={rootStyles.borderRadius.xl}
                        backgroundColor={rootStyles.colors.primary}
                        color={rootStyles.colors.action}
                        height={20}
                    >
                        {
                            isLoading ? "Réservation en cours..." : "Réserver"
                        }
                    </Button>
                </Div>

            </SelectedServicesPane>
        </MainContent>
    )
}

export default SalonReservationUserConnected