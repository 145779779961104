import { Outlet, createBrowserRouter } from "react-router-dom";
import { lazy } from "react";

import { AUTH_PAGE, HOME_PAGE, RESERVATIONS_PAGE, SALONS_PAGE, SALON_RESERVATION_PAGE } from "./navigation_routes";

import AppShell from "../layout/AppShell";
// import HomePage from "../pages/home/HomePage";
// import SalonPage from "../pages/salons/ui/SalonPage";
// import SalonDetailsPage from "../pages/salons/ui/SalonDetailsPage";
import ErrorBoundary from "../components/ErrorBoundary";
import Page404 from "../components/Page404";
import SalonReservationPage from "../pages/salons/ui/SalonReservationPage";
import AuthAppSell from "../layout/AuthAppSell";
import SalonReservationUserConnected from "../pages/salons/ui/SalonReservationUserConnected";
import ReservationsHistoryPage from "../pages/reservations/ui/ReservationsHistoryPage";

const HomePage = lazy(() => import("../pages/home/HomePage"))
const SalonPage = lazy(() => import("../pages/salons/ui/SalonPage"))
const SalonDetailsPage = lazy(() => import("../pages/salons/ui/SalonDetailsPage"))

export const router = createBrowserRouter([
    {
        path: HOME_PAGE,
        element: <AppShell />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: "/",
                element: <HomePage />
            },
            {
                path: SALONS_PAGE,
                element: <Outlet />,
                children: [
                    {
                        path: ":slug",
                        element: <SalonPage />
                    }
                ]
            },
            {
                path: ":slug",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <SalonDetailsPage />
                    },
                    {
                        path: SALON_RESERVATION_PAGE,
                        element: <SalonReservationPage />
                    }
                ]
            },
            {
                path: "*",
                element: <Page404 />
            }
        ]
    },
    {
        path: AUTH_PAGE,
        element: <AuthAppSell />,
        children: [
            {
                path: SALONS_PAGE,
                element: <Outlet />,
                children: [
                    {
                        path: ":slug",
                        element: <SalonPage />
                    }
                ]
            },
            {
                path: ":slug",
                element: <Outlet />,
                children: [
                    {
                        path: "",
                        element: <SalonDetailsPage />
                    },
                    {
                        path: SALON_RESERVATION_PAGE,
                        element: <SalonReservationUserConnected />
                    }
                ]
            },                                                                                                                                                      
            {
                path: RESERVATIONS_PAGE,
                element: <ReservationsHistoryPage />
            }
        ]
    }
]);