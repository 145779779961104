/***************************************************
 *! CATEGORIES
 * *************************************************
 */
export const CATEGORIES_ENPOINT = "front/categories"

/***************************************************
 *! SALONS
 * *************************************************
 */
export const SALONS_BY_CATEGORIE_ENDPOINT = "front/salons/categories/"
export const DETAILS_SALON_ENDPOINT = "front/salons/"
export const SALONS_ENDPOINT = "front/salons/"

/***************************************************
 *! RESERVATION
 * *************************************************
 */
export const MAKE_NEW_RESERVATION_ENDPOINT = "front/salons/appointments/new"

/***************************************************
 *! AUTHENTICATION
 * *************************************************
 */
export const SIGNIN_ENDPOINT = "user/sign_in"
export const SIGNUP_ENDPOINT = "user"
export const SIGNOUT_ENDPOINT = "user/sign_out"